module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-tinacms-git","id":"b72f1303-4577-53f0-937a-f3c1547654b6","name":"gatsby-tinacms-git","version":"0.29.0","pluginOptions":{"plugins":[],"pathToRepo":"/opt/build/repo","pathToContent":"/","defaultCommitMessage":"Edited from CMS","defaultCommitName":"Hitarth CMS","defaultCommitEmail":"hitarth.sharma12+cms@gmail.com","pushOnCommit":true},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-tinacms-remark","id":"4e049a88-2f70-5135-883f-87a76d01585d","name":"gatsby-tinacms-remark","version":"0.30.0","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]}],"enabled":false,"sidebar":true},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"pathToRepo":"/opt/build/repo","pathToContent":"/","defaultCommitMessage":"Edited from CMS","defaultCommitName":"Hitarth CMS","defaultCommitEmail":"hitarth.sharma12+cms@gmail.com","pushOnCommit":true},
    },{
      plugin: require('../node_modules/gatsby-tinacms-remark/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":920,"linkImagesToOriginal":false,"showCaptions":true,"wrapperStyle":"align-self: center; margin: 0.5rem; text-align: center"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-TX058LL0CT","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
