// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bdc-js": () => import("./../src/pages/bdc.js" /* webpackChunkName: "component---src-pages-bdc-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-list-js": () => import("./../src/pages/book-list.js" /* webpackChunkName: "component---src-pages-book-list-js" */),
  "component---src-pages-coaching-js": () => import("./../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-drafts-js": () => import("./../src/pages/drafts.js" /* webpackChunkName: "component---src-pages-drafts-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-procrastinating-js": () => import("./../src/pages/procrastinating.js" /* webpackChunkName: "component---src-pages-procrastinating-js" */),
  "component---src-pages-standup-js": () => import("./../src/pages/standup.js" /* webpackChunkName: "component---src-pages-standup-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

